import fn from './GlobalService'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { languages } from '../config/global.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: languages.default,
  fallbackLocale: languages.default
})
