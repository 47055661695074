import fn from './GlobalService'
import Vue from 'vue'
import Vuex from 'vuex'
import _forEach from 'lodash/forEach'
import { doors } from '../config/global.json'
import root from '../store/root'
import door from '../store/door'

Vue.use(Vuex)

const store = new Vuex.Store(root)
_forEach(doors, (key) => {
  store.registerModule(key, door)
})

export default store
