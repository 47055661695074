import fn from '../services/GlobalService'
import InputModel from './InputModel'

export default class {
  constructor () {
    this.InputModels = {}
    this.count = 0
  }

  add (InputModel) {
    if (!this.has(InputModel.key)) {
      this.count++
    }
    this.InputModels[InputModel.key] = InputModel
  }

  new (key, value, slider, valueChange) {
    if (!this.has(key)) {
      this.count++
    }
    this.InputModels[key] = new InputModel(key, value, slider, valueChange)
    return this.InputModels[key]
  }

  has (key) {
    return fn.has(this.InputModels, key)
  }

  get (key) {
    if (this.has(key)) {
      return this.InputModels[key]
    }
  }
}
