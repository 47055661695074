import fn from './GlobalService'
import Vue from 'vue'
import Router from 'vue-router'
import Store from './StoreService'
import Bus from './EventService'

import MainTemplate from '../components/templates/MainTemplate'
import HomePage from '../components/pages/HomePage'

Vue.use(Router)

const myRouter = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MainTemplate,
      children: [
        {
          name: 'start',
          path: '',
          component: HomePage
        },
        {
          name: 'cabin',
          path: 'cabin',
          redirect: '/calculator/cabin/evolution100'
        },
        {
          name: 'shaft',
          path: 'shaft',
          redirect: '/calculator/shaft/evolution100'
        },
        {
          name: 'calculator',
          path: 'calculator/:calc/:product',
          component: () => import('../components/pages/CalcPage')
        },
        {
          name: 'help',
          path: 'help',
          component: () => import('../components/pages/HelpPage')
        },
        {
          name: 'error',
          path: 'error',
          component: () => import('../components/pages/NotFoundPage')
        }
      ]
    },
    {
      name: 'print',
      path: '/calculator/print',
      component: () => import('../components/templates/PrintTemplate')
    },
    {
      path: '*',
      redirect: '/error'
    }
  ]
})

myRouter.beforeEach((to, from, next) => {
  if (to.name === 'calculator') {
    var configPromise
    if (
      !fn.inArray(to.params.calc, ['cabin', 'shaft']) ||
      !fn.inArray(to.params.product, ['evolution100', 'evolution200', 'evolution300'])
    ) {
      next('/error')
    }
    configPromise = () => import('../config/' + to.params.product + '.json')
    configPromise().then(
      (config) => {
        Bus.detach('calc')
        Store.commit('init', {calc: to.params.calc, config: config})
        next()
      },
      (reason) => {
        fn.error('failed to load config ' + to.params.product + '.json')
        next('/error')
      }
    )
  } else {
    next()
  }
})

export default myRouter
