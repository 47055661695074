import fn from './GlobalService'
import _isString from 'lodash/isString'
import _forEach from 'lodash/forEach'
import _isFunction from 'lodash/isFunction'
import Vue from 'vue'

const EventService = class {
  constructor () {
    this.vue = new Vue()
    this.map = {}
  }

  /**
   * Emit a global Event to all instances
   *
   * @param {string} name, Name of the event to fire
   * @param {mixed} payload, additional data
   */
  fire (name, payload) {
    this.vue.$emit(name, payload)
  }

  /**
   * Listen to a global Event, registered by any instance
   *
   * @param {string} name, Name of the event to listen
   * @param {function} callback, method to invoke
   */
  listen (name, callback) {
    var ns
    if (_isString(name) && _isFunction(callback)) {
      ns = name.split('/').shift()
      this.vue.$on(name, (payload) => callback(payload))
      if (!fn.has(this.map, ns)) {
        this.map[ns] = []
      }
      if (!fn.inArray(name, this.map[ns])) {
        this.map[ns].push(name)
      }
    } else {
      fn.error('invalid function arguments for EventService::listen')
    }
  }

  /**
   * Detach events
   * @param {string} ns
   */
  detach (ns) {
    _forEach(this.map[ns], (name) => {
      this.vue.$off(name)
    })
  }
}

export default new EventService()
