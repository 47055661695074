import fn from './GlobalService'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Router from './RouterService'
import global from '../config/global.json'

var enabled = false
var hostname = window.location.hostname
var optOutCookieStr = 'ga-disable-' + global.gaID
if (document.cookie.indexOf(optOutCookieStr + '=true') > -1) {
  window[optOutCookieStr] = true
} else if (hostname.toLocaleLowerCase() === global.productionHostname.toLocaleLowerCase()) {
  enabled = true
}

window['googleAnalyticsOptOut'] = function () {
  document.cookie = optOutCookieStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
  window[optOutCookieStr] = true
}

if (enabled) {
  Vue.use(VueAnalytics, {
    id: global.gaID,
    fields: {
      'cookieDomain': 'www.tkelevator.com'
    },
    set: [
      { field: 'anonymizeIp', value: true }
    ],
    debug: {
      enabled: false,
      sendHitTask: true
    },
    router: Router
  })
}

export default enabled
