import fn from '../services/GlobalService'
import { inputs } from '../config/global.json'

export default class {
  constructor (key, value, slider, valueChange) {

    // Properties
    this.key = null
    this.value = value
    this.min = null
    this.max = null
    this.slider = false
    this.notify = false
    this.locked = false
    this.valueChange = false
    this.error = false
    this.step = null
    this.sliderStep = null

    // sanitize given values
    if (fn.inArray(key, inputs)) {
      this.key = key
    }
    if (slider) {
      this.slider = true
    }
    if (fn.inArray(valueChange, ['up', 'down'])) {
      this.valueChange = valueChange
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Setter
  |--------------------------------------------------------------------------
  */

  setValue (val) {
    this.value = val
  }

  setSlider () {
    this.slider = true
  }

  setChangedUp () {
    this.valueChange = 'up'
  }

  setChangedDown () {
    this.valueChange = 'down'
  }

  setChangedNone () {
    this.valueChange = false
  }

  setNotify () {
    this.notify = true
  }

  setLocked () {
    this.locked = true
  }

  setUnlocked () {
    this.locked = false
  }

  /*
  |--------------------------------------------------------------------------
  | Checks
  |--------------------------------------------------------------------------
  */

  is (key) {
    return this.key === key
  }

  compare (value) {
    return this.value === value
  }

  isSlider () {
    return this.slider
  }

  hasChangedUp () {
    return this.valueChange === 'up'
  }

  hasChangedDown () {
    return this.valueChange === 'down'
  }

  hasNotify () {
    return this.notify
  }

  isLocked () {
    return this.locked
  }
}
