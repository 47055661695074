import fn from './GlobalService'
import _forEach from 'lodash/forEach'
import CalculationService from './CalculationService'
import InputModel from '../models/InputModel'

const CalculationHelperService = class {
  // constructor () {}

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * just a switch function
   * @param {string} calc
   * @param {object} inputs, node from $store.state
   * @param {InputModel} Input
   * @return {InputModel}
   */
  getDependentValue (calc, inputs, Input) {
    if (calc === 'cabin') {
      if (Input.is('kb')) {
        return this.getTbValueFromKb(inputs.tb, Input)
      }
      if (Input.is('tb')) {
        return this.getKbValueFromTb(inputs.kb, Input)
      }
    }
    if (calc === 'shaft') {
      if (Input.is('sb')) {
        return this.getTbValueFromSb(inputs.tb, Input)
      }
      if (Input.is('tb')) {
        return this.getSbValueFromTb(inputs.sb, Input)
      }
    }
    return null
  }

  getMinMaxValue (calc, inputs, Input) {
    if (calc === 'cabin') {
      if (Input.is('kb')) {
        return this.getTbMaxfromKb(inputs.tb, Input)
      }
      if (Input.is('tb')) {
        return this.getKbMinFromTb(inputs.kb, Input)
      }
    }
    if (calc === 'shaft') {
      if (Input.is('sb')) {
        return this.getTbMaxFromSb(inputs.tb, Input)
      }
      if (Input.is('tb')) {
        return this.getSbMinFromTb(inputs.sb, Input)
      }
    }
    return null
  }

  /**
   * check, if there is an input, that has dependent min/max-values on a locked input
   *
   * @param {object} rootState
   * @param {InputModel} Input, can be null to autocheck the last locked input
   */
  getLockedDependent (rootState, Input) {
    if (Input === 'auto') {
      if (!rootState.locked) {
        return null
      }
      Input = new InputModel(rootState.locked, rootState.inputs[rootState.locked].value)
      Input.setLocked()
    }
    return this.getMinMaxValue(rootState.calc, rootState.inputs, Input)
  }

  getSeFromKbKt (kb, kt, options) {
    var Input, i
    Input = new InputModel('se')
    for (i = 0; i < options.length; i++) {
      if (options[i].set === undefined) {
        Input.value = options[i].value
        continue
      }
      if (options[i].set.kb === kb && options[i].set.kt === kt) {
        Input.value = options[i].value
        break
      }
    }
    return Input
  }

  /**
   * get URL params for print window
   */
  getPrintParams (globals, result, inputs, tipps, params) {
    var query, name, msgs, msg, option
    query = []
    for (name in globals) {
      query.push(name + '=' + globals[name])
    }
    _forEach(params, (name) => {
      query.push(name + '=' + result[name])
    })
    if (globals.calc === 'cabin' && inputs.se_cabin.value !== 'none') {
      option = fn.getOption(inputs.se_cabin.value, inputs.se_cabin.options)
      query.push('se1=' + option.parse.kb)
      query.push('se2=' + option.parse.kt)
      query.push('se3=' + option.parse.load)
    } else if (inputs.se_shaft.value !== 'none') {
      option = fn.getOption(inputs.se_shaft.value, inputs.se_shaft.options)
      query.push('se1=' + option.parse.sb)
      query.push('se2=' + option.parse.st)
      query.push('se3=' + option.parse.load)
    }
    msgs = []
    _forEach(tipps, (values) => {
      msg = []
      msg.push(values[0])
      if (values[1]) {
        msg.push(fn.boolToNumber(values[1]))
      }
      if (values[2]) {
        msg.push(fn.boolToNumber(values[2]))
      }
      msgs.push(msg.join('-'))
    })
    query.push('msgs=' + msgs.join(','))
    return query.join('&')
  }

  /*
  |--------------------------------------------------------------------------
  | Value Calculation methods for tb, kb, sb
  |--------------------------------------------------------------------------
  */

  /**
   * check if door width must be adjusted
   * @param {object} node, settings from store.inputs.tb
   * @param {InputModel} Input
   * @return {InputModel}
   */
  getTbValueFromKb (tb, kbInput) {
    var tbValueMax

    // car can always become wider
    if (kbInput.hasChangedUp()) {
      return null
    }
    tbValueMax = CalculationService.subCalcDoorWidthMaxFromCarWidth(kbInput.value)
    if (tbValueMax < tb.value) {
      return new InputModel('tb', fn.sanitize(tbValueMax, tb.min, tb.max, tb.step, 'down'), kbInput.slider)
    }
    return null
  }

  /**
   * check if car width must be adjusted
   * @param {object} node, settings from store.inputs.kb
   * @param {InputModel} Input
   * @return {InputModel}
   */
  getKbValueFromTb (kb, tbInput) {
    var kbValueMin

    // door can always become smaller
    if (tbInput.hasChangedDown()) {
      return null
    }
    kbValueMin = CalculationService.subCalcCarWidthMin(tbInput.value)
    if (kbValueMin > kb.value) {
      return new InputModel('kb', fn.sanitize(kbValueMin, kb.min, kb.max, kb.step, 'up'), tbInput.slider)
    }
    return null
  }

  /**
   * check if door width must be adjusted
   * @param {object} node, settings from store.inputs.tb
   * @param {InputModel} Input
   * @return {InputModel}
   */
  getTbValueFromSb (tb, sbInput) {
    var tbValueMax

    // shaft can always become wider
    if (sbInput.hasChangedUp()) {
      return null
    }
    tbValueMax = CalculationService.subCalcDoorWidthMaxFromShaftWidth(sbInput.value)
    if (tbValueMax < tb.value) {
      return new InputModel('tb', fn.sanitize(tbValueMax, tb.min, tb.max, tb.step, 'down'), sbInput.slider)
    }
    return null
  }

  /**
   * check if car width must be adjusted
   * @param {object} node, settings from store.inputs.sb
   * @param {InputModel} Input
   * @return {object}
   */
  getSbValueFromTb (sb, tbInput) {
    var sbValueMin

    // door can always become smaller
    if (tbInput.hasChangedDown()) {
      return null
    }
    sbValueMin = CalculationService.subCalcShaftWidthMinFromDoorWidth(tbInput.value)
    if (sbValueMin > sb.value) {
      return new InputModel('sb', fn.sanitize(sbValueMin, sb.min, sb.max, sb.step, 'up'), tbInput.slider)
    }
    return null
  }

  /*
  |--------------------------------------------------------------------------
  | Min/Max Calculation methods for tb, kb, sb
  |--------------------------------------------------------------------------
  */

  getTbMaxfromKb (tb, kbInput) {
    var max, Update
    Update = new InputModel('tb')
    Update.min = tb.min // if cabin width is locked, door can always be smaller
    if (kbInput.isLocked()) {
      max = CalculationService.subCalcDoorWidthMaxFromCarWidth(kbInput.value)
      Update.max = fn.sanitize(max, tb.min, tb.max, tb.step, 'down')
    } else {
      Update.max = tb.max
    }
    return Update
  }

  getKbMinFromTb (kb, tbInput) {
    var min, Update
    Update = new InputModel('kb')
    if (tbInput.isLocked()) {
      min = CalculationService.subCalcCarWidthMin(tbInput.value)
      Update.min = fn.sanitize(min, kb.min, kb.max, kb.step, 'up')
    } else {
      Update.min = kb.min
    }
    Update.max = kb.max // if door width is locked, cabin can always become wider
    return Update
  }

  getTbMaxFromSb (tb, sbInput) {
    var max, Update
    Update = new InputModel('tb')
    Update.min = tb.min // if shaft width is locked, door can always become smaller
    if (sbInput.isLocked()) {
      max = CalculationService.subCalcDoorWidthMaxFromShaftWidth(sbInput.value)
      Update.max = fn.sanitize(max, tb.min, tb.max, tb.step, 'down')
    } else {
      Update.max = tb.max
    }
    return Update
  }

  getSbMinFromTb (sb, tbInput) {
    var min, Update
    Update = new InputModel('sb')
    if (tbInput.isLocked()) {
      min = CalculationService.subCalcShaftWidthMinFromDoorWidth(tbInput.value)
      Update.min = fn.sanitize(min, sb.min, sb.max, sb.step, 'up')
    } else {
      Update.min = sb.min
    }
    Update.max = sb.max // if door width is locked, shaft can always become wider
    return Update
  }
}

export default new CalculationHelperService()
