import _includes from 'lodash/includes'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _isNumber from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import _forOwn from 'lodash/forOwn'
import _truncate from 'lodash/truncate'
import _toString from 'lodash/toString'
import { loglevel } from '../config/global.json'

const GlobalService = class {
  constructor () {
    this.buffer = []
    this.consoleWidth = 80
  }

  /*
  |--------------------------------------------------------------------------
  | Some global functions
  |--------------------------------------------------------------------------
  */

  /**
   * my favourite inArray function
   * @param {mixed} needle
   * @param {collection} haystack
   */
  inArray (needle, haystack) {
    return _includes(haystack, needle)
  }

  /**
   * lodash _has is not working!
   * @param {object} object
   * @param {mixed} array or path as string
   */
  has (object, mixed) {
    var keys
    if (!mixed) {
      return false
    }
    if (!_isObject(object)) {
      return false
    }
    if (_isString(mixed)) {
      keys = mixed.split('.')
    } else {
      keys = mixed
    }
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i]
      if (!hasOwnProperty.call(object, key)) {
        return false
      }
      object = object[key]
    }
    return true
  }

  /**
   * Get an option object from a list of objects like defined for input elements
   *
   * @param {mixed} value
   * @param {object} options
   * @return {object}
   */
  getOption (value, options) {
    var i
    value = _toString(value)
    for (i = 0; i < options.length; i++) {
      if (_toString(options[i].value) === value) {
        return options[i]
      }
    }
    return null
  }

  boolToNumber (value) {
    if (value === false) {
      return 0
    }
    if (value === true) {
      return 1
    }
    return value
  }

  round (val, dec) {
    var a
    if (dec === undefined || dec < 0) {
      return Math.round(val) // zero decimal points
    }
    a = Math.pow(10, dec)
    return Math.round(val * a) / a
  }

  parseInt (val) {
    if (val.replace === undefined) {
      val = val.toString()
    }
    val = val.replace(/[^\d,.]/g, '')
    val = val.replace(/,/g, '.')
    return Math.round(val)
  }

  /**
   * make a value a multiple of step between min and max
   * @param {integer} val
   * @param {integer} min
   * @param {integer} max
   * @param {integer} step
   * @param {mixed} round up | down | auto
   */
  sanitize (val, min, max, step, round) {
    var b
    b = val % step
    if (b > 0) {
      switch (round) {
        case 'down':
          val = val - b
          break
        case 'up':
          val = val - b + step
          break
        case 'auto':
          val = val - b
          if (b >= (step / 2)) {
            val += step // round up
          }
          break
      }
    }
    if (val < min) {
      val = min
    } else if (val > max) {
      val = max
    }
    return val
  }

  /*
  |--------------------------------------------------------------------------
  | Logging methods
  |--------------------------------------------------------------------------
  */

  log (mixed, append) {
    if (loglevel >= 2) {
      if (_isObject(mixed)) {
        console.log(mixed)
      } else {
        this.buffer.push(mixed)
        if (!append) {
          this.flush()
        }
      }
    }
  }

  flush () {
    var str = this.buffer.join(' - ')
    if (str.indexOf('%c') >= 0) {
      console.log(str, 'font-weight: bold', 'font-weight: normal')
    } else {
      console.log(str)
    }
    this.buffer = []
  }

  error (mixed) {
    if (loglevel >= 1) {
      console.warn(mixed)
    }
  }
}

export default new GlobalService()
