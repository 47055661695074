import fn from '../services/GlobalService'
import _forOwn from 'lodash/forOwn'
import CalculationService from '../services/CalculationService'
import CalculationHelperService from '../services/CalculationHelperService'
import Bus from '../services/EventService'

export default {
  namespaced: true,
  state () {
    return {
      calculating: false,
      errno: 0,
      errorPanel: 0,
      errorInputs: { kb: false, kt: false, sb: false, st: false, tb: false },

      // config values from /config/doors
      settings: {

        // inter key (t1, t2, t3), also the key of store module
        key: null,

        // product-id (m2t, m2t, m4tz)
        id: null,

        // floor space in m2 per 1m door width
        fl: null,

        // car height
        kh: null,

        // when calculating load, round it up to multiple of
        roundLoad: null,

        // special case in q1: add to shaft pid, if kb/kt is higher than 1600/2100
        sgAddKb1600: null,
        sgAddKt2100: null,

        // Reduce door with in dependance to car with, must not be smaller than 25 due  to E in door.js::subCalcWa1FromDoor.
        tbKbRed: null,

        // substraction for door niche
        tn: null,

        // substraction for deep door niches, not used in interface (so far), but needed for tip
        tnDeep: null,

        // substraction for door niches 2 doors
        tnDl: null,

        // substraction for deep door niches, not used in interface (so far), but needed for tip
        tnDlDeep: null,

        // door depth
        tts: null,

        // wa addition, if load is higher than 3500
        wa1Add3500: null,
        wa2Add3500: null,

        // special case in q1: If kt is smaller than this value, wa3 is the difference bigger. This must be generalized if other loads have similar demands.
        wa3KtBase: null,

        // min width
        min: null,

        // max width
        max: null,

        // Input-Element, overwrite default settings in global.json
        step: null,

        // Input-Element, overwrite default settings in global.json
        sliderStep: null,

        // optional messages, shown in print or on message page. Messages are printed when they exist
        msgE: null,
        msgTn: null
      },

      // calculation results printed in interface
      result: {

        // cabin width
        kb: null,

        // cabin depth
        kt: null,

        // shaft width
        sb: null,

        // shaft depth
        st: null,

        // max. load
        nl: null,

        // number of persons allowed
        pe: null,

        // wall distance left
        wa1: null,

        // wall distance left
        wa2: null,

        // min wall distance back
        wa3: null,

        // car height (_.Core.getConfig('global', 'kh'))
        kh: null,

        // shaft pit
        sg: null,

        // shaft head
        sk: null,

        // load range q1 - q5
        nb: null,

        // min. wall distance left
        wa1Min: null,

        // wall distance left calculated from door
        wa1Door: null,

        // min. wall distance left
        wa2Min: null,

        // wall distance left calculated from door
        wa2Door: null,

        // floor space without door
        fl1: null,

        // floor space for one door
        fl2: null,

        // floor space total
        fl: null
      },
      adjustments: [],
      multi: [],
      tipps: [],
      errmsg: []
    }
  },
  getters: {},
  actions: {

    /**
     */
    selectDoor ({ state, rootState }) {
      var Update
      rootState.door = state.settings.key
      rootState.inputs.tb.min = state.settings.min
      rootState.inputs.tb.max = state.settings.max
      rootState.inputs.tb.step = state.settings.step
      rootState.inputs.tb.sliderStep = state.settings.sliderStep
      Update = CalculationHelperService.getLockedDependent(rootState, 'auto')
      Bus.fire('calc/on-after-door-changed', Update)
    },

    /**
     * Where the magic happens
     */
    calculateDoor: {
      root: true,
      handler ({ state, rootState, rootGetters }, payload) {

        // never true, just for saftey
        if (state.calculating) {
          return
        }
        state.calculating = true
        var Return = CalculationService.do(
          rootState.calc,
          rootState.inputs,
          state.settings,
          payload.slider,
          payload.shaft
        )
        if (!payload.slider) {
          state.adjustments = Return.adjustments
          state.multi = Return.multi
          state.tipps = Return.tipps
          state.errmsg = Return.errmsg
        }

        /**
         * Error handling
         * only use result if no error occured (so freeze old calculation in case of error)
         *
         * state.errno is the errno in every moment
         *
         * | Error | show error | adjust value | show Panel |
         * |-------|------------|--------------|------------|
         * | 1     |            |              | fatal      |
         * | 11    | tb         | tb           | error      |
         * | 12    | tb         | tb           | error      |
         * | 16    | sb, st     | sb, st       | multi      |
         * | 17    | sb         | sb           | error      |
         * | 18    | sb, st     | sb, st       | adjustment |
         * | 19    | kb, kt     | kb, kt       | adjustment |
         * | 20    | tb         | tb           | error      |
         * | 21    | tb         | tb           | error      |
         * | 22    | tb         | tb           | error      |
         * | 23    | kb, kt     | ge           | error      |
         */
        state.errno = Return.errno
        if (state.errno > 0) {
          switch (state.errno) {
            case 1:
              state.errorInputs = { kb: true, kt: true, sb: true, st: true, tb: true }
              if (!payload.slider) {
                state.errorPanel = 8
              }
              break
            case 11:
            case 12:
            case 20:
            case 21:
            case 22:
              state.errorInputs = { kb: false, kt: false, sb: false, st: false, tb: true }
              if (!payload.slider) {
                state.errorPanel = 5
              }
              break
            case 17:
              state.errorInputs = { kb: false, kt: false, sb: true, st: false, tb: false }
              if (!payload.slider) {
                state.errorPanel = 5
              }
              break
            case 16:
              state.errorInputs = { kb: false, kt: false, sb: true, st: true, tb: false }
              if (!payload.slider) {
                state.errorPanel = 7
              }
              break
            case 18:
              state.errorInputs = { kb: false, kt: false, sb: true, st: true, tb: false }
              if (!payload.slider) {
                state.errorPanel = 6
              }
              break
            case 19:
              state.errorInputs = { kb: true, kt: true, sb: false, st: false, tb: false }
              if (!payload.slider) {
                state.errorPanel = 6
              }
              break
            case 23:
              state.errorInputs = { kb: true, kt: true, sb: false, st: false, tb: false }
              if (!payload.slider) {
                state.errorPanel = 5
              }
              break
          }
        } else {

          // store result
          _forOwn(Return.result, (value, key) => {
            state.result[key] = value
          })
          state.errorInputs = { kb: false, kt: false, sb: false, st: false, tb: false }
          state.errorPanel = 0
        }
        state.calculating = false
      }
    }
  },
  mutations: {

    /**
     * Init - getting settings
     * @param {*} state
     * @param {*} setting
     */
    init (state, settings) {
      _forOwn(settings, (value, key) => {
        state.settings[key] = value
      })
    }
  }
}
